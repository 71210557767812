import { Label, Select } from 'flowbite-react';
import { twMerge } from 'tailwind-merge';
import { SelectOption } from '../types/contracts';
import { useEffect } from 'react';

interface InputSelectParams {
    id: string,
    options: Array<SelectOption>,
    placeholder?: string,
    onChange(newValue: any): void,
    value: string,
    isDisabled?: boolean,
    hasIcon?: boolean,
    className?: string
}

const InputSelect: React.FC<InputSelectParams> = (props) => {
    const isValidValue = !!props.value;

    return (
        <Select
            className={twMerge(
                `w-full text-lg placeholder:text-cinza lg:text-base`,
                `${isValidValue && "[&>div>select]:!text-green-500 [&>div>select]:!border-green-500"}`,
                'disabled:cursor-not-allowed disabled:bg-[#cfcfcf] disabled:text-cinza',
                props.className
            )}
            id={`select-${props.id}`}
            disabled={props.isDisabled}
            placeholder={props.placeholder || ''}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
        >
            <option
                key={`select-${props.id}-option-null`}
                value={''}
                className='p-2'
            >
                {props.placeholder || ''}
            </option>
            {
                props.options.map(opt => {
                    return (
                        <option
                            key={`select-${props.id}-option-${opt.value}`}
                            value={opt.value}
                            className='p-2'
                        >
                            {opt.label}
                        </option>
                    )
                })
            }
        </Select>
    );
};

export default InputSelect;