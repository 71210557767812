import React, { createContext } from "react";
import { toast } from "react-toastify";
import AuthGateway from "../gateways/authGateway";
import { getToastMessage, getToastStyle } from "../utils";

interface AuthContextParams {
    doLogin: (email: string, password: string) => Promise<void>;
    doLogout: () => void;
    validateLogin: (token: string) => Promise<boolean>;
}

const AuthContext = createContext<AuthContextParams>({
    doLogin: async () => { },
    doLogout: () => { },
    validateLogin: async () => false
});

export const useAuthContext = () => React.useContext(AuthContext);

interface AuthProviderParams {
    children: React.ReactNode;
    authGateway: AuthGateway;
}

export const AuthProvider: React.FC<AuthProviderParams> = ({
    children,
    authGateway
}) => {
    const doLogin = async (username: string, password: string) => {
        const loginRequest = authGateway.login({ username, password })
        return toast.promise(
            loginRequest,
            getToastMessage('realizar o login, verifique suas credenciais'),
            getToastStyle()
        )
    }

    const doLogout = () => {
        localStorage.removeItem('token');
    };

    const validateLogin = async (token: string) => {
        if (!token) return false;
        const validateRequest = await authGateway.validate(token);
        return validateRequest;
    }

    return (
        <AuthContext.Provider
            value={{
                doLogin,
                doLogout,
                validateLogin
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
export default AuthContext;