import React from 'react';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Router from './Routes';
import { AuthProvider } from './contexts/AuthContext';
import AuthGatewayReal from './gateways/authGatewayReal';
import { ContractProvider } from './contexts/ContractContext';
import ContractGatewatReal from './gateways/contractGatewayReal';

function App() {
    const authGateway = new AuthGatewayReal();
    const contractGateway = new ContractGatewatReal();

    return (
        <React.StrictMode>
            <ToastContainer />
            <AuthProvider authGateway={authGateway}>
                <ContractProvider contractGateway={contractGateway}>
                    <Router />
                </ContractProvider>
            </AuthProvider>
        </React.StrictMode>
    );
}

export default App;
