import ContratoPage from '../../../components/contratoPage';

const ContratoPessoaJuridicaMS = () => {
    const tituloContrato = {
        strong: 'Contrato MS',
        normal: 'Pessoa Jurídica'
    }

    return <ContratoPage
        templateId='MTE3NDEw'
        contractTitle={tituloContrato}
        formFields={[
            "razao_social",
            "CNPJ",
            "endereço_da_empresa",
            'usuario_distribuidora',
            'documento_titular_fatura',
            "nome_do_representante_legal",
            "RG",
            "CPF",
            "nacionalidade",
            "profissao",
            "estado_civil",
            "email",
            "telefone",
            "unidade_consumidora",
            "endereço_da_UC",
            "foto_cnh",
            "media_consumo",
            "fatura_energia",
            "observacoes"
        ]}
    />
}

export default ContratoPessoaJuridicaMS;