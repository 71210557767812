import React from 'react';
import { Link } from 'react-router-dom';

interface ListItemParams {
    children: string | React.ReactNode;
    link?: string;
    isActive: boolean;
}

const ListItem: React.FC<ListItemParams> = ({
    children,
    link,
    isActive
}) => {
    return (
        <li
            onClick={(e) => { e.stopPropagation() }}
            className={`
                ${isActive ? "font-bold" : "font-light"}
                hover:text-blue-600
                transition-all
                duration-300
                ease-in-out
        `}>
            {
                link
                    ? <Link to={link}>{children}</Link>
                    : children
            }
        </li>
    );
}

export default ListItem;