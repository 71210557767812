import { twMerge } from 'tailwind-merge';
import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

interface ContentFormProps extends InputHTMLAttributes<HTMLInputElement> {
	disabled?: boolean;
	type?: 'text' | 'number' | 'password';
	placeholder?: string;
	value?: string | number;
	onChange?: (e: any) => void;
	className?: string;
	name: string;
}

const ContentForm = (props: ContentFormProps) => {
	const { register } = useFormContext();

	const isValidValue = !!props.value;
	const greenColor = 'green-600';

	return (
		<input
			disabled={props.disabled}
			type={props.type}
			placeholder={props.placeholder}
			{...register(props.name)}
			// {...props}
			// {...(props.name ? { ...register(props.name) } : {})}
			value={props.value}
			onChange={props.onChange}
			className={twMerge(
				`
                    border-1 border-solid
                    border-${isValidValue ? greenColor : 'gray'}
                    rounded-[4px]
                    text-green

                    outline-none
                    bg-transparent
                    py-3
                    text-sm
                    text-${isValidValue ? greenColor : 'black'}
                    placeholder-${isValidValue ? greenColor : 'black'}
                `,
				'w-full lg:text-base',
				'disabled:cursor-not-allowed disabled:bg-[#cfcfcf] disabled:text-cinza',
				props.className
			)}
		/>
	);
};

export default ContentForm;
