import Primary from './primary';
import Secondary from './secondary';
import Label from './label';
import Icon from './icon';

const Button = {
    Primary,
    Secondary,
    Label,
    Icon,
};

export default Button;
