import React from 'react';
import ListItem from './listItem';
import { Dropdown } from 'flowbite-react';

type SubItem = {
    link: string;
    label: string;
}

interface DropdownItemParams {
    currentLocation: any;
    title: string;
    baseUrl?: string;
    subs: Array<SubItem>;
}

const DropdownItem: React.FC<DropdownItemParams> = ({
    currentLocation,
    title,
    baseUrl = '',
    subs
}) => {
    const uf = subs[0].link.split('/')[1].split('-')[0];

    return (
        <li>
            <Dropdown
                arrowIcon={false}
                inline
                label={title}
                className={`py-2 px-4 gap-1`}
            >
                {
                    subs.map((subItem, index: number) => {
                        return (
                            <ListItem
                                key={index}
                                link={baseUrl + subItem.link}
                                isActive={currentLocation === subItem.link}
                            >{subItem.label}</ListItem>
                        )
                    })
                }
            </Dropdown>
        </li >
    );
}

export default DropdownItem;