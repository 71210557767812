import ContratoPage from '../../../components/contratoPage';

const ContratoPessoaJuridicaCPFLPiratininga = () => {
    const tituloContrato = {
        strong: 'Contrato SP',
        normal: 'Pessoa Jurídica'
    }

    return <ContratoPage
        templateId='MTExNTM4'
        contractTitle={tituloContrato}
        formFields={[
            "nome_completo",
            "CNPJ",
            "endereço_da_empresa",
            'usuario_distribuidora',
            'senha_distribuidora',
            'documento_titular_fatura',
            "arquivos_adicionais",
            "nome_do_representante_legal",
            "RG",
            "CPF",
            "nacionalidade",
            "profissao",
            "estado_civil",
            "email",
            "telefone",
            "numero_da_instalação",
            "media_consumo",
            "fatura_energia",
            "foto_cnh",
            "observacoes"
        ]}
    />
}

export default ContratoPessoaJuridicaCPFLPiratininga;