import React, { createContext } from "react";
import { toast } from "react-toastify";


import ContractGateway from "../gateways/contractGateway";
import { ContractReducer, initialContract, initialUnidadeConsumidora } from "../reducers/contractReducer";
import { ContractDTO, ContractInfo, ContractUnidadeConsumidoraDTO, SelectOption } from "../types/contracts";
import { capitalizeFirstLetter, getToastMessage, getToastStyle } from "../utils";

interface ContractContextParams {
    isLoading?: boolean;
    contractData: ContractDTO;
    addUcToContract: () => void;
    updateUcProp: (ucIndex: number, key: keyof ContractUnidadeConsumidoraDTO, value: string) => void;
    removeUcFromContract: (ucIndex: number) => void;
    updateContractProp: (key: keyof ContractDTO, value: string) => void;
    updateAllContractProps: (data: ContractDTO) => void;
    getMondayItem: (id: string) => Promise<object>;
    hasDistribuidoraLoginInfo: string;
    setHasDistribuidoraLoginInfo: (value: string) => void;
    getMondayOrigems: () => Promise<Array<SelectOption>>;
    validateContractFields: (fields: Array<keyof ContractDTO> | Array<keyof ContractUnidadeConsumidoraDTO>) => boolean;
    createContract: (mondayId: string, contractInfo: ContractInfo) => Promise<void>;
}

const ContractContext = createContext<ContractContextParams>({
    isLoading: false,
    contractData: initialContract,
    addUcToContract: () => { },
    updateUcProp: () => { },
    removeUcFromContract: () => { },
    updateContractProp: () => { },
    updateAllContractProps: () => { },
    getMondayItem: async () => ({}),
    hasDistribuidoraLoginInfo: 'Sim',
    setHasDistribuidoraLoginInfo: () => { },
    getMondayOrigems: async () => ([]),
    validateContractFields: () => false,
    createContract: async () => { },
});

export const useContractContext = () => React.useContext(ContractContext);

interface ContractProviderParams {
    children: React.ReactNode;
    contractGateway: ContractGateway;
}

export const ContractProvider: React.FC<ContractProviderParams> = ({
    children,
    contractGateway
}) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [contractData, dispatchContract] = React.useReducer(ContractReducer, initialContract);
    const [hasDistribuidoraLoginInfo, setHasDistribuidoraLoginInfo] = React.useState<string>('Sim');

    const createContract = async (mondayId: string, contractInfo: ContractInfo) => {
        setIsLoading(true);
        return toast.promise(
            contractGateway.createContract(mondayId, contractInfo, contractData),
            getToastMessage('criar o contrato'),
            getToastStyle()
        )
            .catch((err: any) => {
                console.error('err > ', err);
                throw new Error(err)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const addUcToContract = () => {
        const currentContract = contractData;
        const contractWithNewUc = {
            ...currentContract,
            ucs: [
                ...currentContract.ucs,
                initialUnidadeConsumidora
            ]
        };
        updateAllContractProps(contractWithNewUc);
    };

    const updateUcProp = (ucIndex: number, key: keyof ContractUnidadeConsumidoraDTO, value: string) => {
        const currentContract = contractData;
        const currentUcs = currentContract.ucs;
        const currentUc = currentUcs[ucIndex];

        let updatedUc: ContractUnidadeConsumidoraDTO = {} as ContractUnidadeConsumidoraDTO;
        if (
            key === 'numero_da_uc'
            || key === 'numero_da_instalação'
            || key === 'unidade_consumidora'
        ) {
            updatedUc = {
                ...currentUc,
                numero_da_instalação: value,
                numero_da_uc: value,
                unidade_consumidora: value,
            };
        } else {
            updatedUc = {
                ...currentUc,
                [key]: value,
            };
        }
        const updatedUcs = currentUcs.map((uc, index) => {
            if (index === ucIndex) {
                return updatedUc;
            }
            return uc;
        });
        const contractWithUpdatedUc = {
            ...currentContract,
            ucs: updatedUcs,
        };
        updateAllContractProps(contractWithUpdatedUc);
    };

    const removeUcFromContract = (ucIndex: number) => {
        const currentContract = contractData;
        const contractWithRemovedUc = {
            ...currentContract,
            ucs: currentContract.ucs.filter((uc, index) => index !== ucIndex)
        };
        updateAllContractProps(contractWithRemovedUc);
    };

    const updateContractProp = (key: keyof ContractDTO, value: string) => {
        dispatchContract({
            type: 'updateProp',
            key,
            value,
        });
    };

    const updateAllContractProps = (data: ContractDTO) => {
        dispatchContract({
            type: 'updateAll',
            contract: data,
        });
    }

    const getMondayItem = async (id: string) => {
        if (!id) {
            toast.error('Por favor, informe o ID do item do monday', getToastStyle())
        }

        return toast.promise(contractGateway.getMondayItemById(id)
            .then((res: any) => {
                const valuesParsed = parseMondayItemToContractDTO(res);
                return valuesParsed;
            }),
            getToastMessage('buscar o item do monday'),
            getToastStyle()
        );
    }

    const getMondayOrigems = async () => {
        const origems = await contractGateway.getMondayOrigems()
            .then((res: any) => {
                return res;
            });

        const origemsParsed = origems.map((origem: string) => {
            return {
                label: origem,
                value: origem,
            }
        });
        return origemsParsed;
    }

    const validateContractFields = (fields: Array<keyof ContractDTO> | Array<keyof ContractUnidadeConsumidoraDTO>): boolean => {
        const isFormFieldsValid: Array<boolean> = [];

        for (const field of fields) {
            if (field === 'ucs') {
                const ucs = contractData.ucs;
                const isValidUcs = ucs.map((uc) => {
                    const ucKeys = Object.keys(uc) as Array<keyof ContractUnidadeConsumidoraDTO>;
                    const isUcValid = validateUcFields(uc, ucKeys);
                    return isUcValid;
                });
                isFormFieldsValid.push(isValidUcs.every((isValid) => isValid));
            }

            if (
                (
                    (
                        field === 'usuario_distribuidora'
                        || field === 'senha_distribuidora'
                    )
                    && hasDistribuidoraLoginInfo === 'Sim'
                    && !validateLoginDistribuidora(field)
                )
            ) {
                toast.warn(
                    `Por favor, preencha o campo ${capitalizeFirstLetter(field.split('_').join(' '))}`, getToastStyle());
                return false;
            }

            if (field === 'usuario_distribuidora' && hasDistribuidoraLoginInfo === 'Não') continue;
            if (field === 'senha_distribuidora' && hasDistribuidoraLoginInfo === 'Não') continue;

            else {
                if (
                    field !== 'arquivos_adicionais'
                    && field !== 'documento_titular_fatura'
                ) {
                    const contractFieldValue = !!contractData[field as keyof ContractDTO];
                    isFormFieldsValid.push(contractFieldValue);
                }
            }
        };

        const isAllFieldsValid = isFormFieldsValid.every((isValid) => isValid);
        return isAllFieldsValid;
    }

    const validateLoginDistribuidora = (
        contractKey: keyof ContractDTO,
    ): boolean => {
        if (!contractData[contractKey]) return false;
        return true;
    };

    const validateUcFields = (
        uc: ContractUnidadeConsumidoraDTO,
        fields: Array<keyof ContractUnidadeConsumidoraDTO>
    ): boolean => {
        for (const field of fields) {
            const contractFieldValue = uc[field];

            if (field === 'observacoes') continue;
            if (
                field === 'foto_cnh'
                || field === 'fatura_energia'
            ) {
                const isFileValid = validateFiles(field, contractFieldValue as FileList);
                if (isFileValid) continue;
                toast.warn(
                    `Por favor, preencha o campo ${capitalizeFirstLetter(field.split('_').join(' '))}`, getToastStyle());
                return false;
            };

            if (
                (
                    typeof contractFieldValue === 'object'
                    && !(contractFieldValue as SelectOption).value
                )
                || (
                    !contractFieldValue
                )
            ) {
                toast.warn(
                    `Por favor, preencha o campo ${capitalizeFirstLetter(field.split('_').join(' '))}`, getToastStyle());
                return false;
            }
        };
        return true;
    }

    const validateFiles = (field: string, files: FileList) => {
        return true;
        const filesArray = Array.from(files);
        if (filesArray.length === 0) return false;

        const isFileValid = filesArray.map((file) => {
            if (!file) {
                toast.warn(
                    `Por favor, preencha o campo ${capitalizeFirstLetter(field.split('_').join(' '))}`, getToastStyle());
                return false;
            };
            return true;
        });
        return isFileValid.every((file) => file === true);
    };

    const parseMondayItemToContractDTO = (mondayItem: ContractDTO) => {
        const contractParsed: ContractDTO = initialContract;
        console.log('contractParsed > ', contractParsed);
        contractParsed.boleto_unico = mondayItem.boleto_unico === 'Sim' ? '1' : '0'
            || initialContract.boleto_unico;
        contractParsed.estado = mondayItem.estado || initialContract.estado;
        contractParsed.origem = mondayItem.origem || initialContract.origem;

        const fields = Object.keys(contractParsed);
        fields.forEach((field) => {
            const contractField = field as keyof ContractDTO;

            if (contractField === 'ucs') {
                const currentUc: ContractUnidadeConsumidoraDTO = contractParsed.ucs![0];
                const contractUcDataKeys = Object.keys(currentUc);

                contractUcDataKeys.forEach((ucKey) => {
                    const mondayItemUcData = mondayItem as unknown as ContractUnidadeConsumidoraDTO;
                    const ucKeyContract = ucKey as keyof ContractUnidadeConsumidoraDTO;
                    const mondayItemValue: string = mondayItemUcData[ucKeyContract] as unknown as string;

                    if (mondayItemValue) {
                        contractParsed.ucs![0][ucKeyContract] = mondayItemValue;
                    }
                })
            } else {
                const mondayItemValue = mondayItem[contractField as keyof ContractDTO];
                // console.log('contractField, mondayItemValue > ', contractField, mondayItemValue);

                // TODO: fix essa gambiarra, problema na tipagem pq os arquivos
                // estão como unknown ai da erro
                // Dar um jeito de criar um ContractData e um ContractDTO
                if (
                    mondayItemValue
                    && contractField !== 'arquivos_adicionais'
                    && contractField !== 'documento_titular_fatura'
                ) {
                    contractParsed[contractField] = mondayItemValue as string;
                }
            }
        })
        return contractParsed;
    };

    return (
        <ContractContext.Provider
            value={{
                isLoading,
                contractData,
                addUcToContract,
                updateUcProp,
                removeUcFromContract,
                updateContractProp,
                updateAllContractProps,
                getMondayItem,
                hasDistribuidoraLoginInfo,
                setHasDistribuidoraLoginInfo,
                getMondayOrigems,
                validateContractFields,
                createContract,
            }}
        >
            {children}
        </ContractContext.Provider>
    );
};
export default ContractContext;