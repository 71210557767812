import { twMerge } from 'tailwind-merge';

interface SecondaryProps {
    children: React.ReactNode;
    disabled?: boolean;
    className?: string;
    onClick?: () => void;
    type?: 'button' | 'submit';
    transparent?: boolean;
}

export const Secondary = (props: SecondaryProps) => {
    return (
        <button
            className={twMerge(
                'font-semibold flex cursor-pointer items-center justify-between gap-3 rounded-bl-xl bg-white px-10 py-3 text-white transition-all duration-300 ease-in',
                'border-2 border-azul text-azul',
                'hover:bg-azul hover:text-white',
                'disabled:cursor-not-allowed disabled:bg-[#cfcfcf] disabled:text-cinza',
                'disabled:border-gray disabled:hover:bg-[#cfcfcf] disabled:hover:text-cinza',
                '[&>svg>g>path]:fill-azul',
                '[&>svg>g>path]:hover:fill-white',
                props.className
            )}
            type={props.type}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
};

export default Secondary;