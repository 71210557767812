import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
    className?: string;
    label?: string;
}

const Label = (props: LabelProps) => {
    return (
        <label
            {...props}
            className={twMerge(
                'box-border rounded-lg text-lg outline-none duration-300',
                'max-w-md rounded-none lg:max-w-l',
                'flex flex-col items-start justify-center gap-2',
                props.className
            )}
        >
            {props.label && <span className='text-gray-600'>{props.label}</span>}
            {props.children}
        </label>
    );
};

export default Label;
