

import ContratoPage from '../../components/contratoPage';

const ContratoPessoaJuridicaSC = () => {
    const tituloContrato = {
        strong: 'Contrato SC',
        normal: 'Pessoa Jurídica'
    }

    return <ContratoPage
        templateId='MTE4Mzc3'
        contractTitle={tituloContrato}
        formFields={[
            "razao_social",
            "CNPJ",
            "endereço_da_empresa",
            'usuario_distribuidora',
            'documento_titular_fatura',
            "nome_do_representante_legal",
            "RG",
            "CPF",
            "nacionalidade",
            "profissao",
            "estado_civil",
            "email",
            "telefone",
            "unidade_consumidora",
            "foto_cnh",
            "media_consumo",
            "fatura_energia",
            "observacoes"
        ]}
    />
}

export default ContratoPessoaJuridicaSC;