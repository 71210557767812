import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import Button from '../components/button';
import { Input } from '../components/input';
import { LoginData, loginSchema } from '../types/types';

import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '../assets/arrow-right-line.svg';
import { ReactComponent as LockIcon } from '../assets/lock-icon.svg';
import { ReactComponent as LogoPlin } from '../assets/logo-plin.svg';
import { ReactComponent as LogotipoPlin } from '../assets/logotipo-plin.svg';
import { ReactComponent as UserIcon } from '../assets/user-icon.svg';
import { useAuthContext } from '../contexts/AuthContext';

const Login = () => {
	const navigate = useNavigate();

	const loginForm = useForm<LoginData>({
		resolver: zodResolver(loginSchema),
	});

	const {
		handleSubmit,
		formState: { errors },
	} = loginForm;

	const { doLogin } = useAuthContext();

	const handleLogin = async (data: LoginData): Promise<void> => {
		const { username, password } = data;
		await doLogin(username, password)
			.then(() => {
				navigate('/home');
				return;
			})
			.catch(() => { });
	};

	React.useEffect(() => {
		const token = localStorage.getItem('token') ?? '';
		if (token) {
			navigate('/home');
			return;
		}
	}, []);

	return (
		<div
			className='
            h-screen
            w-screen
            flex
            justify-center
            items-center
        '
		>
			<section
				className='
                h-full
                w-1/2
                bg-gradient-primary
                pl-36
                flex
                flex-col
                justify-start
                gap-[20%]
            '
			>
				<LogoPlin
					className='
                    w-48
                    h-16
                    mt-8
                    [&>path]:fill-white
                '
				/>
				<h1
					className='
                    text-8xl
                    font-bold
                    leading-[140%]
                    text-white
                '
				>
					Economia de
					<br />
					energia
					<br />
					simples assim
				</h1>
			</section>

			<section
				className='
                h-full
                w-1/2
                flex
                flex-col
                justify-center
                items-center
                [&>*]:w-[57.5%]
            '
			>
				<FormProvider {...loginForm}>
					<form
						className='
                            flex
                            flex-col
                            justify-center
                            items-start
                            gap-12
                            [&>*:not(svg)]:w-full
                        '
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit(handleLogin)(e);
						}}
					>
						<LogotipoPlin
							className={`
                            w-18
                            h-18
                        `}
						/>

						<h2
							className='
                            font-bold
                            text-5xl
                        '
						>
							Login
						</h2>

						<Input.Label
							className={twMerge(
								'max-w-md rounded-none lg:max-w-lg',
								'flex-row items-center',
								'border-b-2 border-solid border-preto',
								!!errors.username && 'border-vermelho'
							)}
						>
							<Input.Icon
								Icon={UserIcon}
								className='w-6 h-6'
								hasError={!!errors.username}
							/>
							<Input.ContentForm
								required
								type='text'
								name='username'
								placeholder='username'
								className={twMerge(
									'w-full text-lg text-preto placeholder:text-cinza lg:text-base',
									'border-none',
									!!errors.username &&
									'placeholder:text-vermelho'
								)}
							/>

							{!!errors.username && (
								<Input.ErrorField
									error={!!errors.username}
									message={errors.username.message || ''}
									title={true}
								/>
							)}
						</Input.Label>

						<Input.Label
							className={twMerge(
								'max-w-md rounded-none lg:max-w-lg',
								'flex-row items-center',
								'border-b-2 border-solid border-preto',
								!!errors.password && 'border-vermelho'
							)}
						>
							<Input.Icon
								Icon={LockIcon}
								className='w-6 h-6'
								hasError={!!errors.password}
							/>
							<Input.ContentForm
								required
								type='password'
								name='password'
								placeholder='password'
								className={twMerge(
									'w-full text-lg text-preto placeholder:text-cinza lg:text-base',
									'border-none',
									!!errors.password &&
									'placeholder:text-vermelho'
								)}
							/>
						</Input.Label>

						<Button.Primary
							type='submit'
							className='mt-10 max-w-md py-4 text-lg lg:mt-5 lg:max-w-lg'
						>
							<Button.Label label='entrar' />
							<Button.Icon Icon={ArrowRightIcon} />
						</Button.Primary>
					</form>
				</FormProvider>
			</section>
		</div>
	);
};

export default Login;
