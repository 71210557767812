import React from 'react';

import { useContractContext } from '../../contexts/ContractContext';
import { ContractUnidadeConsumidoraDTO } from '../../types/contracts';

import { Input } from '../input';

import Button from '../button';

import { ReactComponent as AddCircleLineBlue } from "../../assets/add-circle-line.svg";
import { capitalizeFirstLetter } from '../../utils';
import InputFile from '../inputFile';

interface FormUnidadeConsumidoraProps {
    ucIndex: number;
    className: string,
    onSubmit: () => void;
    fields: Array<keyof ContractUnidadeConsumidoraDTO>;
    contractData: ContractUnidadeConsumidoraDTO;
    disabled?: boolean;
    isLastUc?: boolean;
}

const FormUnidadeConsumidora: React.FC<FormUnidadeConsumidoraProps> = ({
    ucIndex,
    className,
    onSubmit,
    fields,
    contractData,
    disabled = true,
    isLastUc = false,
}) => {
    const {
        addUcToContract,
        updateUcProp,
        validateContractFields
    } = useContractContext();

    const verifyDataBeforeSubmit = async () => {
        if (validateContractFields(fields)) {
            return await onSubmit();
        }
    }

    return (
        <form
            className={className}
            onSubmit={(e: any) => {
                e.preventDefault();
                verifyDataBeforeSubmit();
            }}
        >
            {
                fields.map((field: string, i: number) => {
                    const fieldCapitalized = capitalizeFirstLetter(field.split('_').join(' '));

                    if (
                        field === 'numero_da_uc'
                        || field === 'numero_da_instalação'
                        || field === 'unidade_consumidora'
                    ) {
                        return <Input.Label
                            key={`${ucIndex}-${i}`}
                            label={fieldCapitalized}
                        >
                            <Input.Content
                                disabled={disabled}
                                type='text'
                                value={
                                    contractData.numero_da_uc
                                    || contractData.numero_da_instalação
                                    || contractData.unidade_consumidora
                                }
                                placeholder={fieldCapitalized}
                                onChange={(e: any) => {
                                    updateUcProp(ucIndex, 'numero_da_uc', e.target.value);
                                }}
                                required
                            />
                        </Input.Label>
                    }

                    if (
                        field === 'endereço_da_UC'
                        || field === 'endereço_da_instalação'
                    ) {
                        return <Input.Label
                            key={`${ucIndex}-${i}`}
                            label={fieldCapitalized}
                        >
                            <Input.Content
                                disabled={disabled}
                                type='text'
                                value={contractData[field] || ''}
                                placeholder={fieldCapitalized}
                                onChange={(e: any) => {
                                    updateUcProp(ucIndex, field, e.target.value);
                                }}
                                required
                            />
                            <p className='text-xs text-gray-400'>
                                *exatamente como está na fatura
                            </p>
                        </Input.Label>
                    }

                    if (field === 'media_consumo') {
                        return <Input.Label
                            key={`${ucIndex}-${i}`}
                            label={fieldCapitalized}
                        >
                            <Input.SufixContent
                                sufix='kWh'
                                disabled={disabled}
                                type='number'
                                value={contractData[field] || ''}
                                placeholder={fieldCapitalized}
                                onChange={(e: any) => {
                                    updateUcProp(ucIndex, field, e.target.value);
                                }}
                                required
                            />
                        </Input.Label>
                    }

                    if (field === 'observacoes') {
                        return <textarea
                            disabled={disabled}
                            key={`${ucIndex}-${i}`}
                            value={contractData[field] || ''}
                            placeholder={'Observações'}
                            onChange={(e: any) => {
                                updateUcProp(ucIndex, field, e.target.value);
                            }}
                            className={`
                                w-full
                                col-span-2
                                col-end-auto
                                self-end
                                max-w-md
                                py-4
                                text-lg
                                lg:max-w-lg
                            `}
                        />
                    }

                    if (
                        field === 'fatura_energia'
                        || field === 'foto_cnh'
                    ) {
                        return <Input.Label
                            key={`${field}-${i}`}
                            label={field === 'foto_cnh' ? 'Foto CNH' : fieldCapitalized}
                        >
                            <InputFile
                                isDisabled={disabled}
                                id={field}
                                onChange={(newValue) => updateUcProp(ucIndex, field, newValue)}
                                isMultiple={false}
                            />
                        </Input.Label>
                    }

                    else {
                        return;
                    }
                })
            }
            {
                isLastUc && <>
                    <Button.Secondary
                        disabled={disabled}
                        type='button'
                        onClick={addUcToContract}
                        className={`
                    w-full
                    col-span-2
                    col-end-auto
                    self-end
                    max-w-md
                    py-4
                    text-lg
                    lg:max-w-lg
                `}
                    >
                        <Button.Label label="add U.C." />
                        <Button.Icon Icon={AddCircleLineBlue} />
                    </Button.Secondary>

                    {/* <Button.Primary
                        disabled={disabled}
                        type='submit'
                        className={`
                    w-full
                    col-span-2
                    col-end-auto
                    self-end
                    max-w-md
                    py-4
                    text-lg
                    lg:max-w-lg
                `}
                    >
                        <Button.Label label="Criar contrato" />
                        <Button.Icon Icon={ArrowRightIcon} />
                    </Button.Primary> */}
                </>
            }
        </form>
    );
}

export default FormUnidadeConsumidora;