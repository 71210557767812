import React from "react";
import { useLocation } from "react-router-dom";

import { ReactComponent as LogoPlin } from "../../assets/logo-plin.svg";
import Item from "./listItem";
import DropdownItem from "./dropdownItem";

const Drawer: React.FC = () => {
    const location = useLocation();
    const currentLocation = location.pathname;

    return (
        <section className="
            top-0
            fixed
        ">
            <menu className="
                h-screen
                w-60
                pt-16
                px-8
            ">
                <LogoPlin />
                <nav className="
                    pt-36
                ">
                    <ul className="
                        list-none
                        text-2lg
                        space-y-4
                    ">
                        <DropdownItem
                            currentLocation={currentLocation}
                            title="COPEL"
                            baseUrl="/copel"
                            subs={[
                                {
                                    link: "/pr-pj",
                                    label: "Pessoa Jurídica"
                                },
                                {
                                    link: "/pr-pf",
                                    label: "Pessoa Física"
                                }
                            ]}
                        />

                        <DropdownItem
                            currentLocation={currentLocation}
                            title="CELESC"
                            baseUrl="/celesc"
                            subs={[
                                {
                                    link: "/sc-pj",
                                    label: "Pessoa Jurídica"
                                }
                            ]}
                        />

                        <DropdownItem
                            currentLocation={currentLocation}
                            title="ENERGISA MT"
                            baseUrl="/energisa"
                            subs={[
                                {
                                    link: "/mt-pj",
                                    label: "MT - Pessoa Jurídica"
                                },
                                {
                                    link: "/mt-pf",
                                    label: "MT - Pessoa Física"
                                },
                            ]}
                        />
                        <DropdownItem
                            currentLocation={currentLocation}
                            title="ENERGISA MS"
                            baseUrl="/energisa"
                            subs={[
                                {
                                    link: "/ms-pj",
                                    label: "MS - Pessoa Jurídica"
                                },
                                {
                                    link: "/ms-pf",
                                    label: "MS - Pessoa Física"
                                }
                            ]}
                        />

                        <DropdownItem
                            currentLocation={currentLocation}
                            title="CEMIG"
                            baseUrl="/cemig"
                            subs={[
                                {
                                    link: "/mg-pj",
                                    label: "Pessoa Jurídica"
                                },
                                {
                                    link: "/mg-pf",
                                    label: "Pessoa Física"
                                }
                            ]}
                        />

                        <DropdownItem
                            currentLocation={currentLocation}
                            title="ELEKTRO"
                            baseUrl="/elektro"
                            subs={[
                                {
                                    link: "/sp-pj",
                                    label: "Pessoa Jurídica"
                                },
                                {
                                    link: "/sp-pf",
                                    label: "Pessoa Física"
                                }
                            ]}
                        />

                        <DropdownItem
                            currentLocation={currentLocation}
                            title="CPFL PAULISTA"
                            baseUrl="/cpfl/paulista"
                            subs={[
                                {
                                    link: "/sp-pj",
                                    label: "Pessoa Jurídica"
                                },
                                {
                                    link: "/sp-pf",
                                    label: "Pessoa Física"
                                }
                            ]}
                        />

                        <DropdownItem
                            currentLocation={currentLocation}
                            title="CPFL PIRATININGA"
                            baseUrl="/cpfl/piratininga"
                            subs={[
                                {
                                    link: "/sp-pj",
                                    label: "Pessoa Jurídica"
                                },
                                {
                                    link: "/sp-pf",
                                    label: "Pessoa Física"
                                }
                            ]}
                        />

                        <DropdownItem
                            currentLocation={currentLocation}
                            title="CPFL SANTA CRUZ"
                            baseUrl="/cpfl/santa-cruz"
                            subs={[
                                {
                                    link: "/sp-pj",
                                    label: "Pessoa Jurídica"
                                },
                                {
                                    link: "/sp-pf",
                                    label: "Pessoa Física"
                                }
                            ]}
                        />

                        <DropdownItem
                            currentLocation={currentLocation}
                            title="CEEE"
                            baseUrl="/ceee"
                            subs={[
                                {
                                    link: "/rs-pj",
                                    label: "Pessoa Jurídica"
                                },
                                {
                                    link: "/rs-pf",
                                    label: "Pessoa Física"
                                }
                            ]}
                        />

                        <DropdownItem
                            currentLocation={currentLocation}
                            title="RGE-SUL"
                            baseUrl="/rge-sul"
                            subs={[
                                {
                                    link: "/rs-pj",
                                    label: "Pessoa Jurídica"
                                },
                                {
                                    link: "/rs-pf",
                                    label: "Pessoa Física"
                                }
                            ]}
                        />
                    </ul>
                </nav>
            </menu >
        </section >
    );
};

export default Drawer;