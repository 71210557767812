import { ReactComponent as ErrorWarning } from '../../assets/error-warning-fill.svg';

interface ErrorProps {
    error: boolean;
    message: string;
    title?: boolean;
}

const Error = (props: ErrorProps) => {
    return (
        <>
            {props.error ? (
                <div className="flex items-center gap-1">
                    <ErrorWarning />
                    <p className="text whitespace-nowrap text-xs text-vermelho">
                        {props.message}
                    </p>
                </div>
            ) : (
                props.title && (
                    <p className="text whitespace-nowrap text-xs text-vermelho">
                        {props.message}
                    </p>
                )
            )}
        </>
    );
};

export default Error;
