import React from "react";
import { toast } from "react-toastify";

import { ReactComponent as TrashCanIcon } from "../assets/trash-can-icon.svg";

import { getToastStyle, makeId } from "../utils";

interface InputFileProps {
    id: string,
    onChange(newValue: any): void,
    isMultiple?: boolean,
    isDisabled?: boolean,
    hasIcon?: boolean,
    className?: string
}

const InputFile: React.FC<InputFileProps> = (props) => {
    const [id, setId] = React.useState('');

    const inputFileRef = React.useRef<HTMLInputElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (
            inputFileRef.current
            && inputFileRef.current.files
            && inputFileRef.current.files[0].size > 3000000
        ) {
            inputFileRef.current.value = '';
            props.onChange([]);
            return toast.warn(
                'Por favor, insira um arquivo menor que 3MB',
                getToastStyle()
            );
        }

        if (inputFileRef.current) {
            props.onChange(inputFileRef.current.files);
        }

    };

    const handleDelete = () => {
        if (inputFileRef.current) {
            inputFileRef.current.value = '';
            props.onChange([]);
        }
    }

    React.useEffect(() => {
        const newId = makeId();
        setId(newId);
    }, []);

    return <div className={`
        flex
        flex-row
        items-center
        justify-center
    `}>
        <input
            type="file"
            className="w-full text-lg text-preto placeholder:text-cinza lg:text-base"
            id={`input-${props.id}`}
            disabled={props.isDisabled}
            multiple={props.isMultiple}
            onChange={handleChange}
            ref={inputFileRef}
        />
        <button type="button" onClick={handleDelete} >
            <TrashCanIcon />
        </button>
    </div>
}

export default InputFile;