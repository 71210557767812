import {
	ContractDTO,
	ContractUnidadeConsumidoraDTO,
	SelectOption,
} from './types/contracts';

export const API_URL = process.env.REACT_APP_API_URL || '';
// export const API_URL = 'http://localhost:3333';

export const MONDAY_BOARD_ID = '5722670621';

export const MONDAY_KEYS: Array<
	[keyof ContractDTO | keyof ContractUnidadeConsumidoraDTO, string]
> = [
	['nome_do_representante_legal', 'name'],
	['nome_do_representante_legal', 'label8'],
	['numero_da_uc', 'texto1'],
	['unidade_consumidora', 'texto1'],
	['numero_da_instalação', 'texto1'],
	['RG', 'n_meros73'],
	['CPF', 'n_meros9'],
	['CNPJ', 'n_meros9'],
	['observacoes', 'texto_longo9'],
	['email', 'e_mail'],
	['telefone', 'texto'],
	['media_consumo', 'n_meros'],

	['data_primeiro_contato', 'data'],
	['data_apresentacao_proposta', 'data6'],
	['data_envio_termo_adesao', 'data65'],

	['nome_da_empresa', 'name'],
	// ['endereço_da_empresa', 'texto_longo'],
	// ['endereço_da_instalação', 'texto13'],
	// ['endereço_da_UC', 'texto13'],
	['origem', 'status'],
	// ['troca_titularidade', 'troca_titularidade'],
	// ['boleto_unico', 'boleto_unico'],
	['estado_civil', 'lista_suspensa1'],
	['profissao', 'texto855'],
	['usuario_distribuidora', 'texto13'],
	['senha_distribuidora', 'texto92'],
	['estado', 'estado'],
	['nacionalidade', 'nacionalidade'],
];

export const SimNaoSelect: Array<SelectOption> = [
	{ value: 'Sim', label: 'Sim' },
	{ value: 'Não', label: 'Não' },
];

export const OrigemSelect: Array<SelectOption> = [
	{
		label: 'NC',
		value: 'NC',
	},
	{
		label: 'Prospecção',
		value: 'Prospecção',
	},
	{
		label: 'AMBEV',
		value: 'AMBEV',
	},
	{
		label: 'SDR',
		value: 'SDR',
	},
	{
		label: 'Indicação Cliente',
		value: 'Indicação Cliente',
	},
	{
		label: 'Passiva',
		value: 'Passiva',
	},
	{
		label: 'Representantes',
		value: 'Representantes',
	},
	{
		label: 'Indicação Sócios',
		value: 'Indicação Sócios',
	},
];
