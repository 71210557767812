import axios, { Axios } from 'axios';
import { LoginData } from '../types/types';
import AuthGateway from './authGateway';
import { API_URL } from '../constants';

export default class AuthGatewayReal implements AuthGateway {
	axios: Axios;

	constructor() {
		this.axios = axios.create({
			baseURL: API_URL,
		});
	}

	private setToken(token: string) {
		this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		localStorage.setItem('token', token);
	}

	async login(data: LoginData): Promise<void> {
		const { username, password } = data;
		return this.axios
			.post(`/login`, {
				username,
				password,
			})
			.then(({ data }) => {
				this.setToken(data.accessToken);
				return data;
			})
			.catch((error) => {
				throw new Error(error);
			});
	}

	async validate(token: string): Promise<boolean> {
		this.setToken(token);
		return this.axios
			.get('/check')
			.then((response) => {
				return true;
			})
			.catch((error) => {
				return false;
			});
	}
}
