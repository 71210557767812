import React from 'react';
import { ReactComponent as PlacasSolaresFundo } from '../assets/placas-solares-fundo.svg';

const Home = () => {
    return (
        <div className='
            flex
            flex-col
            items-start
            justify-evenly
            w-full
            h-screen
            bg-white
            pl-[6.5rem]
        '>
            <h1 className='
                text-5xl
                font-bold
                self-start
                text-black
            '>Selecione o contrato</h1>
            <PlacasSolaresFundo className="w-[calc(100% - 6.5rem)]" />
        </div>
    );
}

export default Home;