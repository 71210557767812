import { toast } from 'react-toastify';

export const getToastMessage = (msg: string) => ({
	pending: 'Carregando...',
	success: `sucesso ao ${msg}!`,
	error: `Ocorreu um erro ao ${msg}!`,
});

export const getToastStyle = () => ({
	position: toast.POSITION.TOP_CENTER,
	pauseOnHover: true,
	pauseOnFocusLoss: false,
	autoClose: 5000,
	style: {
		minWidth: '150px',
		minHeight: '120px',
		size: '16px',
		top: '30vh',
		borderRadius: '5px',
		zIndex: 9000,
	},
});

export function formatCnpj(cnpj: string) {
	if (cnpj === undefined) return 'NC';
	return cnpj.replace(
		/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
		'$1.$2.$3/$4-$5',
	);
}

export function formatTelefone(telefone: string) {
	if (telefone === undefined) return 'NC';
	return telefone.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
}

export const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeInputMask = (value: string) => {
	return value.replace(/\D/g, '');
};

export const makeId = () => {
	const numericId = Math.random() * 10 ** 6;
	const intId = Math.floor(numericId);
	const stringId = `${intId}`;
	return stringId;
};
