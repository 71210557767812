import React from 'react';
import { twMerge } from 'tailwind-merge';

import Button from '../components/button';
import { Input } from '../components/input';
import { useContractContext } from '../contexts/ContractContext';
import { ContractDTO, ContractInfo, ContractUnidadeConsumidoraDTO, SelectOption } from '../types/contracts';

import { ReactComponent as ArrowRightIcon } from '../assets/arrow-right-line.svg';
import { ReactComponent as TrashCanIcon } from "../assets/trash-can-icon.svg";

import estadosSelect from '../components/form/estados.json';
import { SimNaoSelect } from '../constants';
import FormPessoaFisica from './form/formPessoaFisica';
import FormPessoaJuridica from './form/formPessoaJuridica';
import FormRepresentanteLegal from './form/formRepresentanteLegal';
import FormUnidadeConsumidora from './form/formUnidadeConsumidora';
import InputSelect from './inuptSelect';

type ContratoTitleText = {
	strong: string;
	normal: string;
};

interface ContratoPage {
	templateId: string;
	contractTitle: ContratoTitleText;
	formFields: Array<keyof ContractDTO | keyof ContractUnidadeConsumidoraDTO>,
}

const ContratoPage: React.FC<ContratoPage> = ({
	templateId,
	contractTitle,
	formFields,
}) => {
	const {
		isLoading,
		updateContractProp,
		updateAllContractProps,
		getMondayItem,
		contractData,
		createContract,
		validateContractFields,
		removeUcFromContract,
		getMondayOrigems
	} = useContractContext();

	const handleUpdateContractDates = (key: keyof ContractDTO, value: string) => {
		updateContractProp(key, value);
		updateContractProp(key, value);
	}

	const [mondayId, setMondayId] = React.useState<string>('');
	const [gotMondayItem, setGotMondayItem] = React.useState<boolean>(false);
	const [origems, setOrigems] = React.useState<Array<SelectOption>>([]);
	const [createContractStatus, setCreateContractStatus] = React.useState<null | boolean>(null);

	React.useEffect(() => {
		getMondayOrigems()
			.then((data) => {
				setOrigems(data);
			})
			.catch((err) => {
				console.error('err > ', err);
			});
	}, []);

	const handleGetMondayItem = async () => {
		await getMondayItem(mondayId)
			.then((data: any) => {
				setGotMondayItem(true);
				updateAllContractProps(data);
			})
			.catch((err: any) => {
				setGotMondayItem(false);
				console.error('err > ', err);
			});
	};

	const handleSubmitForm = async () => {
		const contratoInfo: ContractInfo = {
			templateId,
			contractTitle: contractTitle.strong + ' ' + contractTitle.normal,
		};

		await createContract(mondayId, contratoInfo)
			.then((data) => {
				setCreateContractStatus(true);
			})
			.catch((err) => {
				setCreateContractStatus(false);
			})
	};

	const isDisabled = isLoading || !gotMondayItem;

	const formTitleStyle = twMerge(`
		text-2xl
		text-gray-600
		font-bold
		mb-4
	`);

	return (
		<div
			className={`
				flex
				flex-col
				items-end
				justify-center
				bg-gradient-bg
				hscreen
				h-full
			`}
		>
			{
				(createContractStatus === true || createContractStatus === false) && <div className={`
					z-50
					h-screen
					w-full
					fixed
					top-0
					left-0
					${createContractStatus && 'bg-[#4f4a]'}
					${!createContractStatus && 'bg-[#f44a]'}

					flex
					flex-col
					items-center
					justify-center
					text-xl
					font-bold
					text-black
				`}>
					<h1 className={`bg-white p-2 rounded text-${createContractStatus ? 'green-500' : 'red-500'}`}>
						{createContractStatus === true && "Contrato criado com sucesso!"}
						{createContractStatus === false && "Ocorreu um erro ao criar o contrato!"}
					</h1>
					<br />
					<button
						onClick={() => setCreateContractStatus(null)}
						className={`
							py-2
							px-4
							bg-azul
							text-white
							rounded
							text-lg
							transition-all
							duration-200
							hover:text-azul
							hover:bg-white
						`}
					>Fechar</button>
				</div>
			}

			<div
				className={`
                flex
                flex-col
                items-between
                justify-center
                w-[97.5%]
                h-full
                py-[6.25rem]
                px-[6.5rem]
                bg-white
            `}
			>
				<h1
					className={`
                    text-5xl
                    text-gray-600
                    font-bold
                `}
				>
					<strong className='text-preto'>
						{contractTitle.strong}
					</strong>{' '}
					{contractTitle.normal}
				</h1>
				<section
					className={`
                    flex
                    flex-col
                    items-start
                    justify-start
                    w-full
                    h-full
                    mt-[8rem]
                    gap-[5rem]
                `}
				>
					<div className={`
						flex
						flex-row
						items-start
						justify-start
						gap-8
					`}>
						<form
							onSubmit={(e: any) => {
								e.preventDefault();
								handleGetMondayItem();
							}}
							className={`
								flex
								flex-row
								items-center
								justify-center
								gap-8
							`}
						>
							<Input.Label
								className={twMerge(
									'max-w-md rounded-none lg:max-w-lg'
								)}
							>
								<Input.Content
									required
									type='number'
									placeholder='Id do item do monday'
									value={mondayId}
									onChange={(e) => setMondayId(e.target.value)}
									className={twMerge(
										'w-full text-lg text-preto placeholder:text-cinza lg:text-base'
									)}
								/>
							</Input.Label>
							<Button.Secondary
								type='submit'
								className='max-w-md py-2 text-lg lg:max-w-lg'
							>
								<Button.Label label='Buscar item do monday' />
								<Button.Icon Icon={ArrowRightIcon} />
							</Button.Secondary>
						</form>
					</div>
					<div className={`
						w-full
						flex
						flex-row
						items-center
						justify-start
						gap-8
					`}>
						<Input.Label
							className='w-1/6'
							label='Origem'
						>
							<InputSelect
								id='origem'
								isDisabled={isDisabled}
								placeholder='Origem'
								options={origems}
								onChange={(newValue) => updateContractProp('origem', newValue)}
								value={contractData.origem}
							/>
						</Input.Label>
						<Input.Label
							className='w-1/6'
							label='Estado'
						>
							<InputSelect
								id='estado'
								isDisabled={isDisabled}
								placeholder='Estado'
								options={estadosSelect}
								onChange={(newValue) => updateContractProp('estado', newValue)}
								value={contractData.estado}
							/>
						</Input.Label>

						<Input.Label
							className='w-1/6'
							label='Possui boleto único?'
						>
							<InputSelect
								id='boleto_unico'
								isDisabled={isDisabled}
								placeholder='Possui boleto único?'
								options={SimNaoSelect}
								onChange={(newValue) => updateContractProp('boleto_unico', newValue)}
								value={contractData.boleto_unico}
							/>
						</Input.Label>
					</div>
					<div>
						<div className={`
								mb-12
							`}>
							<FormPessoaJuridica
								onSubmit={handleSubmitForm}
								disabled={isDisabled}
								contractData={contractData}
								fields={formFields as unknown as Array<keyof ContractDTO>}
								className={twMerge(`
										w-full
										grid
										xl:grid-cols-4
										lg:grid-cols-3
										md:grid-cols-2
										sm:grid-cols-1
										gap-x-[1rem]
										gap-y-[2rem]
										items-center
										justify-center
									`)}
							/>
						</div>

						<div className={`
								mb-12
							`}>
							<FormPessoaFisica
								onSubmit={handleSubmitForm}
								disabled={isDisabled}
								contractData={contractData}
								fields={formFields as unknown as Array<keyof ContractDTO>}
								className={twMerge(`
										w-full
										grid
										xl:grid-cols-4
										lg:grid-cols-3
										md:grid-cols-2
										sm:grid-cols-1
										gap-x-[1rem]
										gap-y-[2rem]
										items-center
										justify-center
									`)}
							/>
						</div>

						<div className={`
							flex
							flex-row
							items-center
							justify-start
							gap-8
							mb-12
						`}>
							<Input.Label
								label={'Data Primeiro Contato'}
							>
								<Input.Content
									required
									disabled={isDisabled}
									type='date'
									placeholder={'Data Primeiro Contato'}
									value={contractData.data_primeiro_contato}
									onChange={(e) => handleUpdateContractDates('data_primeiro_contato', e.target.value)}
								/>
							</Input.Label>

							<Input.Label
								label={'Data de Apresentação da Proposta'}
							>
								<Input.Content
									required
									disabled={isDisabled}
									type='date'
									placeholder={'Data de Apresentação da Proposta'}
									value={contractData.data_apresentacao_proposta}
									onChange={(e) => handleUpdateContractDates('data_apresentacao_proposta', e.target.value)}
								/>
							</Input.Label>

							<Input.Label
								label={'Data envio do Termo de Adesão'}
							>
								<Input.Content
									required
									disabled={isDisabled}
									type='date'
									placeholder={'Data envio do Termo de Adesão'}
									value={contractData.data_envio_termo_adesao}
									onChange={(e) => handleUpdateContractDates('data_envio_termo_adesao', e.target.value)}
								/>
							</Input.Label>
						</div>

						{
							<div className={`
								mb-12
							`}>
								<h2 className={formTitleStyle}>Representante Legal</h2>

								<FormRepresentanteLegal
									onSubmit={handleSubmitForm}
									disabled={isDisabled}
									contractData={contractData}
									fields={formFields as unknown as Array<keyof ContractDTO>}
									className={twMerge(`
									w-full
									grid
									xl:grid-cols-4
									lg:grid-cols-3
									md:grid-cols-2
									sm:grid-cols-1
									gap-x-[1rem]
									gap-y-[2rem]
									items-center
									justify-center
								`)}
								/>
							</div>
						}

						{
							contractData.ucs.map(
								(_, index: number) => {
									return <div
										key={index}
										className={`
											mb-12
										`}
									>
										<div className={`
											flex
											flex-row
											items-center
											justify-start
											gap-4
										`}>
											<h2 className={formTitleStyle + ' mb-0'}>Unidade Consumidora</h2>
											{
												index !== 0 && <button onClick={() => removeUcFromContract(index)}><TrashCanIcon /></button>
											}
										</div>
										<FormUnidadeConsumidora
											onSubmit={handleSubmitForm}
											isLastUc={index === contractData.ucs.length - 1}
											key={index}
											ucIndex={index}
											disabled={isDisabled}
											contractData={contractData.ucs[index]}
											fields={formFields as unknown as Array<keyof ContractUnidadeConsumidoraDTO>}
											className={twMerge(`
												w-full
												grid
												xl:grid-cols-4
												lg:grid-cols-3
												md:grid-cols-2
												sm:grid-cols-1
												gap-x-[1rem]
												gap-y-[2rem]
												items-center
												justify-center
											`)}
										/>
									</div>
								}
							)
						}
						<Button.Primary
							type='button'
							onClick={handleSubmitForm}
							className={`
								w-full
								col-span-2
								col-end-auto
								self-end
								max-w-md
								py-4
								text-lg
								lg:max-w-lg
							`}
						>
							<Button.Label label="Continuar" />
							<Button.Icon Icon={ArrowRightIcon} />
						</Button.Primary>
					</div>
				</section>
			</div>
		</div>
	);
};

export default ContratoPage;
