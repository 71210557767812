import React from 'react';
import Drawer from './drawer/drawer';
import { Outlet } from 'react-router-dom';

const Container = () => {
    return (
        <div className='
            flex
            flex-row
            w-full
            h-full
        '>
            <Drawer />
            <div className='w-full pl-60'>
                <Outlet />
            </div>
        </div>
    );
}

export default Container;