import { twMerge } from 'tailwind-merge';

interface PrimaryProps {
    children: React.ReactNode;
    disabled?: boolean;
    className?: string;
    onClick?: () => void;
    type?: 'button' | 'submit';
    transparent?: boolean;
}

export const Primary = (props: PrimaryProps) => {
    return (
        <button
            className={twMerge(
                'font-semibold flex cursor-pointer items-center justify-between gap-3 rounded-bl-xl border-none bg-azul px-10 py-3 text-white transition-all duration-300 ease-in',
                'hover:bg-amarelo hover:text-azul',
                'disabled:cursor-not-allowed disabled:bg-[#cfcfcf] disabled:text-cinza',
                '[&>svg>g>path]:hover:fill-azul',
                props.className
            )}
            type={props.type}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
};

export default Primary;
