import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Container from "./components/container";
import ProtectedRoute from "./components/protectdRoute";
import Home from "./pages/home";
import Login from "./pages/login";
import ContratoPessoaFisicaMT from "./pages/energisa/mt/pessoaFisica";
import ContratoPessoaJuridicaMT from "./pages/energisa/mt/pessoaJuridica";
import ContratoPessoaFisicaMS from "./pages/energisa/ms/pessoaFisica";
import ContratoPessoaJuridicaMS from "./pages/energisa/ms/pessoaJuridica";
import ContratoPessoaFisicaPR from "./pages/copel/pessoaFisica";
import ContratoPessoaJuridicaPR from "./pages/copel/pessoaJuridica";
import ContratoPessoaJuridicaSC from "./pages/celesc/pessoaJuridica";
import ContratoPessoaFisicaMG from "./pages/cemig/pessoaFisica";
import ContratoPessoaJuridicaMG from "./pages/cemig/pessoaJuridica";
import ContratoPessoaFisicaSP from "./pages/elektro/pessoaFisica";
import ContratoPessoaJuridicaSP from "./pages/elektro/pessoaJuridica";
import ContratoPessoaFisicaRS from "./pages/rge-sul/pessoaFisica";
import ContratoPessoaJuridicaRS from "./pages/rge-sul/pessoaJuridica";
import ContratoPessoaFisicaCPFLSantaCruz from "./pages/cpfl/santaCruz/pessoaFisica";
import ContratoPessoaJuridicaCPFLSantaCruz from "./pages/cpfl/santaCruz/pessoaJuridica";
import ContratoPessoaFisicaCPFLPiratininga from "./pages/cpfl/piratininga/pessoaFisica";
import ContratoPessoaJuridicaCPFLPiratininga from "./pages/cpfl/piratininga/pessoaJuridica";
import ContratoPessoaFisicaCPFLPaulista from "./pages/cpfl/paulista/pessoaFisica";
import ContratoPessoaJuridicaCPFLPaulista from "./pages/cpfl/paulista/pessoaJuridica";
import ContratoPessoaFisicaCEEERS from "./pages/ceee/pessoaFisica";
import ContratoPessoaJuridicaCEEERS from "./pages/ceee/pessoaJuridica";

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Container />}>
                    <Route element={<ProtectedRoute />}>
                        <Route index element={<Navigate to="/home" />} />
                        <Route path="home" element={<Home />} />

                        <Route path="copel">
                            <Route path="pr-pj" element={<ContratoPessoaJuridicaPR />} />
                            <Route path="pr-pf" element={<ContratoPessoaFisicaPR />} />
                        </Route>

                        <Route path="celesc">
                            <Route path="sc-pj" element={<ContratoPessoaJuridicaSC />} />
                        </Route>

                        <Route path="energisa">
                            <Route path="mt-pf" element={<ContratoPessoaFisicaMT />} />
                            <Route path="mt-pj" element={<ContratoPessoaJuridicaMT />} />
                            <Route path="ms-pf" element={<ContratoPessoaFisicaMS />} />
                            <Route path="ms-pj" element={<ContratoPessoaJuridicaMS />} />
                        </Route>

                        <Route path="cemig">
                            <Route path="mg-pf" element={<ContratoPessoaFisicaMG />} />
                            <Route path="mg-pj" element={<ContratoPessoaJuridicaMG />} />
                        </Route>

                        <Route path="elektro">
                            <Route path="sp-pf" element={<ContratoPessoaFisicaSP />} />
                            <Route path="sp-pj" element={<ContratoPessoaJuridicaSP />} />
                        </Route>

                        <Route path="rge-sul">
                            <Route path="rs-pf" element={<ContratoPessoaFisicaRS />} />
                            <Route path="rs-pj" element={<ContratoPessoaJuridicaRS />} />
                        </Route>

                        <Route path="cpfl">
                            <Route path="paulista">
                                <Route path="sp-pf" element={<ContratoPessoaFisicaCPFLPaulista />} />
                                <Route path="sp-pj" element={<ContratoPessoaJuridicaCPFLPaulista />} />
                            </Route>

                            <Route path="piratininga">
                                <Route path="sp-pf" element={<ContratoPessoaFisicaCPFLPiratininga />} />
                                <Route path="sp-pj" element={<ContratoPessoaJuridicaCPFLPiratininga />} />
                            </Route>

                            <Route path="santa-cruz">
                                <Route path="sp-pf" element={<ContratoPessoaFisicaCPFLSantaCruz />} />
                                <Route path="sp-pj" element={<ContratoPessoaJuridicaCPFLSantaCruz />} />
                            </Route>
                        </Route>

                        <Route path="ceee">
                            <Route path="rs-pf" element={<ContratoPessoaFisicaCEEERS />} />
                            <Route path="rs-pj" element={<ContratoPessoaJuridicaCEEERS />} />
                        </Route>
                    </Route>
                </Route>

                <Route path="*" element={<Navigate to={'/'} />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
