import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';

const ProtectedRoute = () => {
    const {
        doLogout,
        validateLogin
    } = useAuthContext();

    const navigate = useNavigate();

    const handleTokenValidation = async () => {
        const token = localStorage.getItem("token") ?? "";
        const isValidToken = await validateLogin(token);
        if (!isValidToken) {
            doLogout();
            navigate("/login");
            return;
        }
    }

    React.useEffect(() => {
        handleTokenValidation();
    }, []);

    return (
        <Outlet />
    );
}

export default ProtectedRoute;