import React from 'react';

import { useContractContext } from '../../contexts/ContractContext';
import { ContractDTO } from '../../types/contracts';

import { Input } from '../input';

import { capitalizeFirstLetter, formatCnpj } from '../../utils';

import { SimNaoSelect } from '../../constants';
import InputFile from '../inputFile';
import InputSelect from '../inuptSelect';

interface FormPessoaJuridicaProps {
    className: string
    onSubmit: () => void;
    fields: Array<keyof ContractDTO>;
    contractData: ContractDTO;
    disabled?: boolean;
}

const FormPessoaJuridica: React.FC<FormPessoaJuridicaProps> = ({
    className,
    onSubmit,
    fields,
    contractData,
    disabled = true
}) => {
    const {
        hasDistribuidoraLoginInfo,
        setHasDistribuidoraLoginInfo,
        updateContractProp,
        validateContractFields
    } = useContractContext();

    const handleUpdateContractProp = (key: keyof ContractDTO, value: string) => {
        updateContractProp(key, value);
    }

    const verifyDataBeforeSubmit = async () => {
        if (validateContractFields(fields)) {
            return await onSubmit();
        }
    }

    return (
        <form
            className={className}
            onSubmit={(e: any) => {
                e.preventDefault();
                verifyDataBeforeSubmit()
            }}
        >
            {
                fields.map((field, index: number) => {
                    const fieldCapitalized = capitalizeFirstLetter(field.split('_').join(' '));
                    if (
                        field === 'nome_da_empresa'
                        || field === 'endereço_da_empresa'
                    ) {
                        return <Input.Label
                            key={`${field}-${index}`}
                            label={fieldCapitalized}
                        >
                            <Input.Content
                                required
                                disabled={disabled}
                                type='text'
                                placeholder={fieldCapitalized}
                                value={contractData[field]}
                                onChange={(e) => handleUpdateContractProp(field, e.target.value)}
                            />
                        </Input.Label>

                    }

                    if (field === 'CNPJ') {
                        return <Input.Label
                            key={`${field}-${index}`}
                            label={field}
                        >
                            <Input.MaskedContent
                                mask='99.999.999/9999-99'
                                required
                                disabled={disabled}
                                type='text'
                                placeholder="CNPJ"
                                value={contractData.CNPJ}
                                onChange={(e) => handleUpdateContractProp('CNPJ', formatCnpj(e.target.value))}
                            />
                        </Input.Label>
                    }

                    if (field === 'usuario_distribuidora') {
                        // TODO: set a key here
                        return <>
                            <Input.Label
                                label='Possui login na distribuidora?'
                            >
                                <InputSelect
                                    id='exist-distribuidora-user'
                                    isDisabled={disabled}
                                    placeholder='Possui login na distribuidora?'
                                    options={SimNaoSelect}
                                    onChange={(newValue) => setHasDistribuidoraLoginInfo(newValue)}
                                    value={hasDistribuidoraLoginInfo}
                                />
                            </Input.Label>

                            <Input.Label
                                label={'Usuário Distribuidora'}
                            >
                                <Input.Content
                                    required
                                    disabled={disabled || hasDistribuidoraLoginInfo === 'Não'}
                                    type='text'
                                    placeholder={'Usuário Distribuidora'}
                                    value={contractData.usuario_distribuidora}
                                    onChange={(e) => handleUpdateContractProp('usuario_distribuidora', e.target.value)}
                                />
                            </Input.Label>

                            <Input.Label
                                label={'Senha Distribuidora'}
                            >
                                <Input.Content
                                    required
                                    disabled={disabled || hasDistribuidoraLoginInfo === 'Não'}
                                    type='text'
                                    placeholder={'Senha Distribuidora'}
                                    value={contractData.senha_distribuidora}
                                    onChange={(e) => handleUpdateContractProp('senha_distribuidora', e.target.value)}
                                />
                            </Input.Label>
                        </>
                    }

                    if (field === 'documento_titular_fatura') {
                        return <Input.Label
                            key={`${field}-${index}`}
                            label={fieldCapitalized}
                        >
                            <InputFile
                                isDisabled={disabled}
                                id={field}
                                onChange={(newValue) => handleUpdateContractProp(field, newValue)}
                                isMultiple={false}
                            />
                            <p className='text-xs text-gray-400'>
                                *contrato social
                            </p>
                        </Input.Label>
                    }

                    {
                        if (field === 'arquivos_adicionais') {
                            return <Input.Label
                                key={`${field}-${index}`}
                                label={fieldCapitalized}
                            >
                                <InputFile
                                    isDisabled={disabled}
                                    id={field}
                                    onChange={(newValue) => handleUpdateContractProp(field, newValue)}
                                    isMultiple={true}
                                />
                            </Input.Label>
                        }
                    }

                    return;
                })
            }

            {/* <Button.Primary
                disabled={disabled}
                type='submit'
                className={`
                    w-full
                    col-span-2
                    col-end-auto
                    self-end
                    max-w-md
                    py-4
                    text-lg
                    lg:max-w-lg
                `}
            >
                <Button.Label label="Continuar" />
                <Button.Icon Icon={ArrowRightIcon} />
            </Button.Primary> */}
        </form >
    );
}

export default FormPessoaJuridica;