import Icon from './icon';
import Label from './label';
import Content from './content';
import SufixContent from './sufixContent';
import MaskedContent from './maskedContent';
import ErrorField from './errorField';
import ContentForm from './contentForm';

export const Input = {
    Icon,
    Label,
    Content,
    SufixContent,
    MaskedContent,
    ErrorField,
    ContentForm,
};
