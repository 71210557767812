import {
	ContractActions,
	ContractDTO,
	ContractUnidadeConsumidoraDTO,
} from '../types/contracts';

export function ContractReducer(
	contract: ContractDTO,
	action: ContractActions,
) {
	switch (action.type) {
		case 'updateProp': {
			return {
				...contract,
				[action.key]: action.value,
			};
		}
		case 'updateAll': {
			return {
				...action.contract,
			};
		}

		default: {
			throw new Error(`Ação inválida: ${action}`);
		}
	}
}

export const initialUnidadeConsumidora: ContractUnidadeConsumidoraDTO = {
	numero_da_uc: '',
	unidade_consumidora: '',
	numero_da_instalação: '',
	endereço_da_instalação: '',
	endereço_da_UC: '',
	media_consumo: '',
	fatura_energia: [],
	foto_cnh: [],
	observacoes: '',
};

export const initialContract: ContractDTO = {
	boleto_unico: '',
	origem: '',
	estado: '',
	nome_da_empresa: '',
	razao_social: '',
	endereço_da_empresa: '',
	CNPJ: '',
	usuario_distribuidora: '',
	senha_distribuidora: '',
	documento_titular_fatura: [],
	arquivos_adicionais: [],
	data_primeiro_contato: new Date().toISOString().split('T')[0],
	data_apresentacao_proposta: new Date().toISOString().split('T')[0],
	data_envio_termo_adesao: new Date().toISOString().split('T')[0],

	nome_do_representante_legal: '',
	RG: '',
	CPF: '',
	nacionalidade: '',
	profissao: '',
	estado_civil: '',
	email: '',
	telefone: '',

	nome_completo: '',
	endereço_da_residencia: '',
	data_de_nascimento: '',

	ucs: [initialUnidadeConsumidora],
};
