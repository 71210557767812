import { twMerge } from "tailwind-merge";

interface IconParams {
    Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    className?: string;
    hasError?: boolean;
}

const Icon: React.FC<IconParams> = ({
    Icon,
    className,
    hasError
}) => {
    return <Icon className={twMerge("w-4 h-4", className)} />;
};

export default Icon;
