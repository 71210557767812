import { twMerge } from 'tailwind-merge';

interface ContentProps {
    disabled?: boolean;
    type?: 'text' | 'number' | 'password' | 'email' | 'date' | 'file';
    placeholder?: string;
    value: string | undefined;
    onChange: (e: any) => void;
    className?: string;
    required?: boolean;
}

const Content = (props: ContentProps) => {
    const isValidValue = !!props.value;
    const greenColor = 'green-600'
    return (
        <input
            disabled={props.disabled}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            className={twMerge(
                `
                    border-1 border-solid
                    border-${isValidValue ? greenColor : 'gray'}
                    rounded-[4px]
                    text-green

                    outline-none
                    bg-transparent
                    py-3
                    text-sm
                    text-${isValidValue ? greenColor : 'black'}
                    placeholder-${isValidValue ? greenColor : 'black'}
                `,
                'w-full lg:text-base',
                'disabled:cursor-not-allowed disabled:bg-[#cfcfcf] disabled:text-cinza',
                props.className
            )}
        />
    );
};

export default Content;
