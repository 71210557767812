import axios, { Axios } from 'axios';
import { API_URL, MONDAY_BOARD_ID } from '../constants';
import {
	ContractDTO,
	ContractInfo,
	ContractUnidadeConsumidoraDTO,
} from '../types/contracts';
import { removeInputMask } from '../utils';
import ContractGateway from './contractGateway';

type UcsInfo = {
	mondayId: string;
	numeroUc: string;
};

export default class ContractGatewatReal implements ContractGateway {
	axios: Axios;

	constructor() {
		this.axios = axios.create({
			baseURL: API_URL,
		});
	}

	private getFromSessionAndSetToken() {
		const token = localStorage.getItem('token');
		this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	}

	async createContract(
		mondayId: string,
		contractInfo: ContractInfo,
		contractData: ContractDTO,
	) {
		this.getFromSessionAndSetToken();

		const { contractTitle, templateId } = contractInfo;

		const ucs: Array<ContractUnidadeConsumidoraDTO> = contractData.ucs.map(
			(uc: ContractUnidadeConsumidoraDTO) => {
				return {
					...uc,
				};
			},
		);

		const data: ContractDTO = {
			...contractData,
			ucs,
			CNPJ: removeInputMask(contractData.CNPJ),
			RG: removeInputMask(contractData.RG),
			CPF: removeInputMask(contractData.CPF),
			estado_civil: contractData.estado_civil,
			nacionalidade: contractData.nacionalidade,
			telefone: removeInputMask(contractData.telefone),
			email: `${contractData.email} ${contractData.email}`,
			nome_completo: contractData.nome_do_representante_legal,
			origem: contractData.origem as unknown as string,
			boleto_unico: Boolean(contractData.boleto_unico) ? 'Sim' : 'Não',
			estado: contractData.estado as unknown as string,
		};

		let currentUcsFiles = {};
		contractData.ucs.map((uc: ContractUnidadeConsumidoraDTO) => {
			currentUcsFiles = {
				...currentUcsFiles,
				[uc.numero_da_uc!]: {
					arquivos_adicionais: contractData.arquivos_adicionais,
					documento_titular_fatura:
						contractData.documento_titular_fatura,
					fatura_energia: uc.fatura_energia,
					foto_cnh: uc.foto_cnh,
				},
			};
		});

		const ucsInfo: Array<UcsInfo> = await this.axios
			.post(
				`/create-contract`,
				{
					templateId,
					contractTitle,
					data,
				},
				{
					params: {
						'monday-item-id': mondayId,
					},
				},
			)
			.then(({ data }) => data.ucsInfo)
			.catch((err) => {
				console.log(err);
				throw new Error(err.message);
			});

		const ucsPromises = ucsInfo.map(async (uc) => {
			const { mondayId, numeroUc } = uc;

			const currentUcFiles: any =
				currentUcsFiles[numeroUc as keyof typeof currentUcsFiles];

			const {
				arquivos_adicionais: arquivosAdicionais,
				documento_titular_fatura: documentoTitularFatura,
				fatura_energia: faturaEnergia,
				foto_cnh: fotoCnh,
			}: {
				[key: string]: FileList | [];
			} = currentUcFiles;

			const files: Array<File> = [
				...Array.from(arquivosAdicionais),
				...Array.from(documentoTitularFatura),
				...Array.from(faturaEnergia),
				...Array.from(fotoCnh),
			];

			const links: Array<string> = [];
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const fileUrl = await this.createFileLink(
					MONDAY_BOARD_ID,
					mondayId,
					file.name,
				);
				links.push(fileUrl);
			}

			const uploadPromises = links.map(async (link, index) => {
				await axios.put(link, files[index], {
					headers: {
						'Content-Type': undefined,
					},
				});
			});

			await Promise.all(uploadPromises);
		});

		await Promise.all(ucsPromises);
	}

	async getMondayItemById(id: string) {
		this.getFromSessionAndSetToken();
		const mondayItem = await this.axios
			.get(`/get-item-monday?monday-item-id=${id}`)
			.then(({ data }) => {
				return data;
			});

		return mondayItem;
	}

	async getMondayOrigems(): Promise<Array<string>> {
		this.getFromSessionAndSetToken();
		const origems = await this.axios
			.get(`/get-monday-origems`)
			.then(({ data }) => {
				return data;
			});

		return origems;
	}

	private async createFileLink(
		boardId: string,
		mondayId: string,
		fileFullName: string,
	): Promise<string> {
		const fileUrl = await this.axios
			.get(`/create-link-file`, {
				params: {
					'board-id': boardId,
					'item-id': mondayId,
					'file-full-name': fileFullName,
				},
			})
			.then(({ data }) => {
				return data.url;
			});

		return fileUrl;
	}

	private async saveFilesToMonday(url: string) {}
}
