import React from 'react';

import { useContractContext } from '../../contexts/ContractContext';
import { ContractDTO } from '../../types/contracts';

import { Input } from '../input';

import { SimNaoSelect } from '../../constants';
import InputSelect from '../inuptSelect';

interface FormPessoaFisicaProps {
    className: string
    onSubmit: () => void;
    fields: Array<keyof ContractDTO>;
    contractData: ContractDTO;
    disabled?: boolean;
}

const FormPessoaFisica: React.FC<FormPessoaFisicaProps> = ({
    className,
    onSubmit,
    fields,
    contractData,
    disabled = true
}) => {
    const {
        hasDistribuidoraLoginInfo,
        setHasDistribuidoraLoginInfo,
        updateContractProp,
        validateContractFields
    } = useContractContext();

    const handleUpdateContractProp = (key: keyof ContractDTO, value: string) => {
        updateContractProp(key, value);
    }

    const verifyDataBeforeSubmit = async () => {
        if (validateContractFields(fields)) {
            return await onSubmit();
        }
    }

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <form
            className={className}
            onSubmit={(e: any) => {
                e.preventDefault();
                verifyDataBeforeSubmit()
            }}
        >
            {
                fields.map((field, index: number) => {
                    const fieldCapitalized = capitalizeFirstLetter(field.split('_').join(' '));
                    if (field === 'endereço_da_residencia') {
                        return <Input.Label
                            key={index}
                            label={fieldCapitalized}
                        >
                            <Input.Content
                                required
                                disabled={disabled}
                                type='text'
                                placeholder={fieldCapitalized}
                                value={contractData[field]}
                                onChange={(e) => handleUpdateContractProp(field, e.target.value)}
                            />
                        </Input.Label>

                    }

                    if (field === 'usuario_distribuidora') {
                        <>
                            <Input.Label
                                label='Possui login na distribuidora?'
                            >
                                <InputSelect
                                    id='exist-distribuidora-user'
                                    isDisabled={disabled}
                                    placeholder='Possui login na distribuidora?'
                                    options={SimNaoSelect}
                                    onChange={(newValue) => setHasDistribuidoraLoginInfo(newValue)}
                                    value={hasDistribuidoraLoginInfo}
                                />
                            </Input.Label>

                            <Input.Label
                                label={'Usuário Distribuidora'}
                            >
                                <Input.Content
                                    required
                                    disabled={disabled || hasDistribuidoraLoginInfo === 'Não'}
                                    type='text'
                                    placeholder={'Usuário Distribuidora'}
                                    value={contractData.usuario_distribuidora}
                                    onChange={(e) => handleUpdateContractProp(field, e.target.value)}
                                />
                            </Input.Label>

                            <Input.Label
                                label={'Senha Distribuidora'}
                            >
                                <Input.Content
                                    required
                                    disabled={disabled || hasDistribuidoraLoginInfo === 'Não'}
                                    type='text'
                                    placeholder={'Senha Distribuidora'}
                                    value={contractData.senha_distribuidora}
                                    onChange={(e) => handleUpdateContractProp(field, e.target.value)}
                                />
                            </Input.Label>
                        </>
                    }

                    if (
                        field === 'data_de_nascimento'
                    ) {
                        return <Input.Label
                            key={index}
                            label={fieldCapitalized}
                        >
                            <Input.Content
                                required
                                disabled={disabled}
                                type='date'
                                placeholder={fieldCapitalized}
                                value={contractData[field]}
                                onChange={(e) => handleUpdateContractProp('data_de_nascimento', e.target.value)}
                            />
                        </Input.Label>

                    }

                    else {
                        return;
                    }
                })
            }

            {/* <Button.Primary
                disabled={disabled}
                type='submit'
                className={`
                    w-full
                    col-span-2
                    col-end-auto
                    self-end
                    max-w-md
                    py-4
                    text-lg
                    lg:max-w-lg
                `}
            >
                <Button.Label label="Continuar" />
                <Button.Icon Icon={ArrowRightIcon} />
            </Button.Primary> */}
        </form >
    );
}

export default FormPessoaFisica;