import React from 'react';

import { useContractContext } from '../../contexts/ContractContext';
import { ContractDTO } from '../../types/contracts';

import { Input } from '../input';
import InputSelect from '../inuptSelect';

import Button from '../button';
import nacionalidadesOptions from './nacionalidades.json';

import { ReactComponent as ArrowRightIcon } from "../../assets/arrow-right-line.svg";
import { capitalizeFirstLetter } from '../../utils';

interface FormRepresentanteLegalProps {
    className: string
    onSubmit: () => void;
    fields: Array<keyof ContractDTO>;
    contractData: ContractDTO;
    disabled?: boolean;
}

const FormRepresentanteLegal: React.FC<FormRepresentanteLegalProps> = ({
    className,
    onSubmit,
    fields,
    contractData,
    disabled = true
}) => {
    const {
        updateContractProp,
        validateContractFields
    } = useContractContext();

    const verifyDataBeforeSubmit = async () => {
        if (validateContractFields(fields)) {
            return await onSubmit();
        }
    }

    const handleUpdateContractProp = (key: keyof ContractDTO, value: string) => {
        updateContractProp(key, value);
    }

    return (
        <form
            className={className}
            onSubmit={(e: any) => {
                e.preventDefault();
                verifyDataBeforeSubmit()
            }}
        >
            {
                fields.map((field, index: number) => {
                    const fieldCapitalized = capitalizeFirstLetter(field.split('_').join(' '));
                    if (
                        field === 'nome_do_representante_legal'
                        || field === 'profissao'
                    ) {
                        return <Input.Label
                            key={index}
                            label={fieldCapitalized}
                        >
                            <Input.Content
                                required
                                disabled={disabled}
                                type='text'
                                placeholder={fieldCapitalized}
                                value={contractData[field]}
                                onChange={(e) => handleUpdateContractProp(field, e.target.value)}
                            />
                        </Input.Label>

                    }

                    if (field === 'RG') {
                        return <Input.Label
                            key={index}
                            label='RG'
                        >
                            <Input.Content
                                required
                                disabled={disabled}
                                type='text'
                                placeholder="RG"
                                value={contractData.RG}
                                onChange={(e) => handleUpdateContractProp('RG', e.target.value)}
                            />
                        </Input.Label>

                    }

                    if (field === 'CPF') {
                        return <Input.Label
                            key={index}
                            label='CPF'
                        >
                            <Input.MaskedContent
                                mask='999.999.999-99'
                                required
                                disabled={disabled}
                                type='text'
                                placeholder="CPF"
                                value={contractData.CPF}
                                onChange={(e) => handleUpdateContractProp('CPF', e.target.value)}
                            />
                        </Input.Label>
                    }

                    if (field === 'nacionalidade') {
                        return <Input.Label
                            key={index}
                            label='Nacionalidade'
                        >
                            <InputSelect
                                id='nacionalidade'
                                isDisabled={disabled}
                                placeholder='Nacionalidade'
                                options={nacionalidadesOptions}
                                onChange={(newValue) => handleUpdateContractProp('nacionalidade', newValue)}
                                value={contractData.nacionalidade}
                            />
                        </Input.Label>
                    }

                    if (field === 'estado_civil') {
                        return <Input.Label
                            key={index}
                            label='Estado civil'
                        >
                            <InputSelect
                                id='estado_civil'
                                isDisabled={disabled}
                                placeholder='Estado civil'
                                options={[
                                    { value: 'Solteiro', label: 'Solteiro' },
                                    { value: 'Casado', label: 'Casado' },
                                    { value: 'Divorciado', label: 'Divorciado' },
                                    { value: 'Viuvo', label: 'Viúvo' },
                                ]}
                                onChange={(newValue) => handleUpdateContractProp('estado_civil', newValue)}
                                value={contractData.estado_civil}
                            />
                        </Input.Label>
                    }

                    if (field === 'email') {
                        return <Input.Label
                            key={index}
                            label='E-mail'
                        >
                            <Input.Content
                                required
                                disabled={disabled}
                                type='email'
                                placeholder="E-mail"
                                value={contractData.email}
                                onChange={(e) => handleUpdateContractProp('email', e.target.value)}
                            />
                        </Input.Label>
                    }

                    if (field === 'telefone') {
                        return <Input.Label
                            key={index}
                            label='Telefone'
                        >
                            <Input.MaskedContent
                                mask='(99) 9 9999-9999'
                                required
                                disabled={disabled}
                                type='text'
                                placeholder="Telefone"
                                value={contractData.telefone}
                                onChange={(e) => handleUpdateContractProp('telefone', e.target.value)}
                            />
                        </Input.Label>
                    }

                    else {
                        return;
                    }
                })
            }
            {/* <Button.Primary
                disabled={disabled}
                type='submit'
                className={`
                    w-full
                    col-span-2
                    col-end-auto
                    self-end
                    max-w-md
                    py-4
                    text-lg
                    lg:max-w-lg
                `}
            >
                <Button.Label label="Continuar" />
                <Button.Icon Icon={ArrowRightIcon} />
            </Button.Primary> */}
        </form>
    );
}

export default FormRepresentanteLegal;