import { twMerge } from 'tailwind-merge';

interface SufixContentProps {
    disabled?: boolean;
    type?: 'text' | 'number' | 'password' | 'email' | 'date' | 'file';
    placeholder?: string;
    value: string | undefined;
    onChange: (e: any) => void;
    className?: string;
    required?: boolean;
    sufix: string;
}

const SufixContent = (props: SufixContentProps) => {
    const isValidValue = !!props.value;
    const greenColor = 'green-600'
    return (
        <label className={`
            border-1 border-solid
            border-${isValidValue ? greenColor : 'gray'}
            rounded-[4px]

            text-${isValidValue ? greenColor : 'black'}
            pr-3

            'w-full lg:text-base',
            ${props.disabled && 'cursor-not-allowed bg-[#cfcfcf] text-cinza'},
        `}>
            <input
                disabled={props.disabled}
                type={props.type}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                className={twMerge(`
                    text-green

                    border-none
                    outline-none
                    bg-transparent
                    py-3
                    text-sm
                    text-${isValidValue ? greenColor : 'black'}
                    placeholder-${isValidValue ? greenColor : 'black'}
                `,
                    props.className
                )}
            />
            {props.sufix}
        </label>
    );
};

export default SufixContent;
